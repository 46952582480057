import React from 'react'
import {graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import * as styles from './blog-post.module.css'

class StaticPageTemplate extends React.Component {
  render() {
    const page = get(this.props, 'data.contentfulStaticPage')

    return (
      <Layout location={this.props.location}>
        <Seo
          title={page.title}
          description={page.description?.description}
          image={`http:${page.headerImage?.resize.src}`}
        />
        <Hero
          image={page.headerImage?.gatsbyImage}
          title={page.title}
        />
        <div className={styles.container}>
          <div className={styles.article}>
            <div className={styles.body}>
              {renderRichText(page.pageContent)}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default StaticPageTemplate

export const pageQuery = graphql`
  query staticPageBySlug(
    $slug: String!
  ) {
    contentfulStaticPage(slug: { eq: $slug }) {
      headerImage {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      pageContent {
        raw
      }
      title
      description {
        description
      }

    }
  }
`
